import React, { useEffect, useState } from "react";

function MaterialsDescriptionTable({ option }) {
    const [selections, setSelections] = useState({});

    const items = [
        { name: "RYSUNEK ZBIORCZY", standard: "rysunek uproszczony", premium: "rysunek szczegółowy" },
        { name: "PROJEKT INSTALACJI ELEKTRYCZNEJ", standard: "Projekt lokalizacji lamp, gniazd i włączników (bez parametrów przewodów, wymiary w osi lamp)", premium: "Projekt lokalizacji lamp, gniazd i włączników wraz z określeniem lokalizacji wyjścia przewodów i ich parametrów (np. liczba faz lub żył)" },
        { name: "Projekt lokalizacji taśm LED", standard: "Projekt lokalizacji taśm LED (opisowo)", premium: "Projekt lokalizacji taśm LED i zasilaczy (z wymiarami i parametrami)" },
        { name: "ŚCIANY DZIAŁOWE I ELEM. MUROWANE", standard: "z otworami standardowymi", premium: "z otworami dopasowanymi do konkretnych drzwi wybranych producentów" },
        { name: "RZUT", standard: "uproszczony", premium: "szczegółowy, z określeniem materiałów na ścianach" },
        { name: "ZABUDOWY MEBLOWE", standard: "zwymiarowane, z określeniem rodzaju wyposażenia", premium: "szczegółowe, zwymiarowane, zaprojektowane wyposażenie wewnętrzne, rodzaje uchwytów itp." },
        { name: "DOBÓR DRZWI", standard: "podstawowe parametry drzwi standardowych (model drzwi + wymiary otworu w ścianie)", premium: "szczegółowe parametry drzwi (dobór producenta, model drzwi, itd.)" },
        { name: "ROZWINIĘCIA ŚCIAN", standard: "ogólne, zwymiarowane", premium: "szczegółowe, zwymiarowane, z oznaczeniem m.in. wyposażenia wewnętrznego mebli" },
    ];
    // Inicjalizacja stanu w zależności od przekazanej opcji

    useEffect(() => {
        const initialSelections = {};
        items.forEach(item => {
            initialSelections[`${item.name}-standard`] = option === 'standard';
            initialSelections[`${item.name}-premium`] = option === 'premium';
        });
        setSelections(initialSelections);
    }, []);
    const handleCheckboxChange = (event, item, version) => {
        const updatedSelections = {
            ...selections,
            [`${item.name}-${version}`]: event.target.checked,
        };
        setSelections(updatedSelections);

    };

    return (
        <table>
            <thead>
            <tr>
                <th>Element projektu</th>
                <th>Wersja standardowa</th>
                <th>Wersja premium</th>
            </tr>
            </thead>
            <tbody>
            {items.map((item, index) => (
                <tr key={index}>
                    <td>
                        <div className={'section_tooltip'}>
                            <h3>{item.name}</h3>
                            <div className="tooltip">
                                <span className="material-icons">help_outline</span>
                                <span className="tooltiptext">Tu umieść swoje wyjaśnienie dla {item.name}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            name={`${item.name}-standard`}
                            checked={!!selections[`${item.name}-standard`]}
                            disabled={option === 'premium'}
                            onChange={e => handleCheckboxChange(e, item.name, 'standard')}
                        />
                        {item.standard}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            name={`${item.name}-premium`}
                            checked={!!selections[`${item.name}-premium`]}
                            disabled={option === 'standard'}
                            onChange={e => handleCheckboxChange(e, item.name, 'premium')}
                        />
                        {item.premium}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default MaterialsDescriptionTable;
