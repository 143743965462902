import './App.css';
import Form from "./form/Form";
import React from "react";
import ComplexForm from "./form/ComplexForm";
import QuoteForm from "./form/QuoteForm";

function App() {
    return(<>
            <QuoteForm/>
            <ComplexForm/>
            {/*<Form/>*/}
        </>
    )
}

export default App;
