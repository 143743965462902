import React, { useState } from 'react';
import MaterialsDescriptionTable from "./MaterialsDescriptionTable";
import VipOption from "./VipOption";
import './ComplexForm.css';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import 'jspdf-autotable';

function ComplexForm() {

    const [interiorType, setInteriorType] = useState('private');
    const [area, setArea] = useState('');
    const [rooms, setRooms] = useState('');
    const [inventory, setInventory] = useState(false);
    const [needsAnalysis, setNeedsAnalysis] = useState('');
    const [functionalLayout, setFunctionalLayout] = useState('');
    const [visualization, setVisualization] = useState('');
    const [projectType, setProjectType] = useState('standard');
    const [drawingType, setDrawingType] = useState('simplified');
    const [electricalInstallation, setElectricalInstallation] = useState('standard');
    const [hydraulicInstallation, setHydraulicInstallation] = useState(false);
    const [wallType, setWallType] = useState('standard');
    const [privateType, setPrivateType] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [visualizationType, setVisualizationType] = useState('');
    const [materialsList, setMaterialsList] = useState('');
    const [supervisionChecked, setSupervisionChecked] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    };
    const generatePDF = async () => {
        try {

            const doc = new jsPDF();
            // Definiowanie kolumn i danych
            const columns = ["ID", "Name", "Country"];
            const data2 = [
                [1, "Alice", "USA"],
                [2, "Bob", "Canada"],
                [3, "Charlie", "UK"]
            ];

            // Dodawanie tabeli
            doc.autoTable({
                head: [columns],
                body: data2,
            });

            // Zapisywanie dokumentu
            doc.save("table.pdf");

            const canvas = await html2canvas(document.querySelector("#formContainer"), {
                windowHeight: document.querySelector("#formContainer").scrollHeight,
                windowWidth: document.querySelector("#formContainer").scrollWidth});

            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF({
                orientation: 'p',
                compress: 'true',
                unit: 'px',
                format: [canvas.width, canvas.height]
        });

            // pdf.text('Hello world!', 10, 10);
            // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
//// z działąjącego
            const now = new Date();
            const dateString = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}-g-${now.getHours()}-${now.getMinutes()}`;
            const fileName = `Wycena_EmiliaBogdanowiczArchitekt_${dateString}.pdf`;
            // pdf.save(fileName);
            ////
            const blob = await pdf.output('blob');
            console.log("blob",blob)
            const formData = new FormData();
            console.log("form Data",formData)
            // const dateString = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}-${new Date().getHours()}-${new Date().getMinutes()}`;
            // const fileName = `contact_form_${dateString}.pdf`;

            // formData.append("file", blob, fileName);
            formData.append("table.pdf", blob, fileName);

            // Wysyłanie PDF na serwer
            const response = await fetch('https://limakgift.pl/wp-json/form/v1/przyjmij-pdf', {
            // const response = await fetch('http://localhost/wordpress/wp-json/form/v1/przyjmij-pdf', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            const data = await response.json();
            console.log('Success:', data);
        } catch (error) {
            console.error('Error:', error);
        }

    };

// W miejscu generowania PDF:
//     pdf.save('formularz.pdf', {returnPromise: true}).then((pdfBlob) => {
//         sendPDFtoServer(pdfBlob);
//     });


    const handleSubmit = (event) => {
        event.preventDefault();

        //tworzy pdf z formularza
        generatePDF();

        // Tutaj można przetworzyć dane
        console.log({
            interiorType,
            area,
            rooms,
            inventory,
            needsAnalysis,
            functionalLayout,
            visualization,
            projectType,
            drawingType,
            electricalInstallation,
            hydraulicInstallation,
            wallType,
            privateType,
            businessType,
            visualizationType,
            materialsList,
        });

        const formData = new FormData();
        // Dodaj inne dane formularza, jeśli są potrzebne
        if (file) {
            formData.append('file', file);
        }

        // Tutaj możesz wysłać formData do serwera
    };

    return (<>


        <form onSubmit={handleSubmit} className={'formContainer'} id={'formContainer'}>

            <p className={'section'}>Wypełnij poniższą ankietę aby mieć informacje na temat tego jak pracujemy i jakie wiążą się z tym kosztem. Wstępna wycena jest wyłącznie szacunkowa i wymaga doprecyzowania.</p>
            <h2 className={'title'}>Szacowana wartość Twojego Projektu Wnętrz</h2>

            <h2> Informacje ogólne</h2>
            <hr className={'line'}/>

            <div className={'section'}>
                <h3>Rodzaj wnętrza:</h3>
                <div className={'answer_section'}>
                <label >
                    <input type="radio" name="interiorType" value="private" className={'input'} checked={interiorType === 'private'} onChange={() => setInteriorType('private')} /> Prywatne
                </label>
                <label>
                    <input type="radio" name="interiorType" value="public" className={'input'} checked={interiorType === 'public'} onChange={() => setInteriorType('public')} /> Publiczne
                </label>
                </div>

                {interiorType === 'private' && (
                    <div className={'answer_section'}>
                        <div className="label">Typ wnętrza prywatnego:</div>
                        <select className={'input'} value={privateType} onChange={(e) => setPrivateType(e.target.value)}>
                            <option value="">Wybierz</option>
                            <option value="house">Dom</option>
                            <option value="apartment">Mieszkanie</option>
                        </select>
                    </div>
                )}

                {interiorType === 'public' && (
                    <div className={'answer_section'}>
                        <div className="label">Rodzaj działalności:</div>
                        <input type="text" className={'input'} value={businessType} onChange={(e) => setBusinessType(e.target.value)} placeholder="Określ rodzaj działalności" />
                    </div>
                )}

            </div>


            <div className={'section'}>
            <h3>Powierzchnia w m2:</h3>
            <input type="text" value={area} onChange={(e) => setArea(e.target.value)} placeholder="Powierzchnia" />
            </div>

            <div className={'section'}>
            <h3>Ilość pomieszczeń:</h3>
            <input type="number" value={rooms} onChange={(e) => setRooms(e.target.value)} placeholder="Ilość pomieszczeń" />
            </div>

            <div className={'section'}>
            <h3>Inwentaryzacja w naturze:</h3>
                <div className={'answer_section'}>
                    <label>
                        <input type="radio" name="inventory" value="yes" checked={needsAnalysis === 'yes'} onChange={() => setNeedsAnalysis('yes')} /> Tak, potrzebuję
                    </label>
                    <label>
                        <input type="radio" name="inventory" value="no" checked={needsAnalysis === 'no'} onChange={() => setNeedsAnalysis('no')} /> Nie potrzebuję, <br/>mam rysunki techniczne
                    </label>
                    <label htmlFor="file-upload" className="fileLabel">
                        Załącz plik
                    </label>
                    <input id="file-upload" type="file" onChange={handleFileChange} className="fileInput" />
                    <span>{fileName}</span>
                    <div className={'prise'}>Cena: <span>0 </span>zł </div>
                </div>
            </div>

            <h2> Koncepcja</h2>
            <hr className={'line'}/>

            <div className={'section_tooltip'}>
                <h3>Analiza potrzeb </h3>
                <div className="tooltip">
                    <span className="material-icons">help_outline</span>
                    <span className="tooltiptext">Tu umieść swoje wyjaśnienie</span>
                </div>
            </div>

            <div className={'section_conception'}>
                <div className={'answer_section'}>
                    <label>
                        <input type="radio" name="needsAnalysis" value="written" checked={needsAnalysis === 'written'} onChange={() => setNeedsAnalysis('written')} /> Analiza w formie ankiety pisemnej
                    </label>
                    <div className={'prise'}>Cena: <span>0 </span>zł </div>
                </div>
                <div className={'answer_section'}>
                    <label>
                        <input type="radio" name="needsAnalysis" value="meeting" checked={needsAnalysis === 'meeting'} onChange={() => setNeedsAnalysis('meeting')} /> Wnikliwa analiza potrzeb w formie 2-3h spotkania (dostępna również w wersji online)
                    </label>
                    <div className={'prise'}>Cena: <span>0 </span>zł </div>
                </div>

            </div>


            <div className={'section_tooltip'}>
                <h3>Układ funkcjonalny</h3>
                <div className="tooltip">
                    <span className="material-icons">help_outline</span>
                    <span className="tooltiptext">Tu umieść swoje wyjaśnienie</span>
                </div>
            </div>

            <div className={'section'}>
            <label>
                <input type="radio" name="functionalLayout" value="general" checked={functionalLayout === 'general'} onChange={() => setFunctionalLayout('general')} /> Ogólne wymiary
            </label>
            <label>
                <input type="radio" name="functionalLayout" value="detailed" checked={functionalLayout === 'detailed'} onChange={() => setFunctionalLayout('detailed')} /> Szczegółowe wymiary
            </label>
            </div>

            <div className={'section_tooltip'}>
                <h3>Wizualizacje</h3>
                <div className="tooltip">
                    <span className="material-icons">help_outline</span>
                    <span className="tooltiptext">Tu umieść swoje wyjaśnienie</span>
                </div>
            </div>
            <div className={'section'}>
                <div>
                    <label>
                        <input type="radio" name="visualization" value="standard"  className={'input'} checked={visualization === 'standard'} onChange={() => setVisualization('standard')} /> STANDARD
                    </label>
                </div>
            <label>
                <input type="radio" name="visualization" value="premium" className={'input'} checked={visualization === 'premium'} onChange={() => setVisualization('premium')} /> PREMIUM
            </label>
            </div>

            {visualization === 'standard' && (
                <div className={'section'}>
                        <ul className={'description'}>
                            <li>modele użyte w projekcie (m.in. mebli, oświetlenia) pochodzą z bazy 3d (mogą się różnić od dostępnych na rynku elementów wyposażenia wnętrz)</li>

                        </ul>
                </div>
            )}

            {visualization === 'premium' && (
                <>
                <div className={'section'}>
                    <ul className={'description'}>
                        <li>modele odwzorowujące konkretne możliwe do zakupu meble, oświetlenie, dopasowane do budżetu</li>
                        <li>wizualizacje fotorealistyczne (jak zdjęcie) w jakości premium</li>
                        </ul>
                </div>

                    <h3>ZESTAWIENIE MATERIAŁÓW:</h3>
                    <div className={'section'}>
                <label>
                    <input type="radio" name="materialsList" value="standard"  className={'input'} checked={materialsList === 'standard'} onChange={() => setMaterialsList('standard')} /> STANDARD
                </label>
                <label>
                    <input type="radio" name="materialsList" value="premium" className={'input'} checked={materialsList === 'premium'} onChange={() => setMaterialsList('premium')} /> PREMIUM
                </label>
            </div>

            {materialsList === 'standard' && (
                <div className={'section'}>
                    <ul className={'description'}>
                        <li>lista produktów gotowych (wraz z ilością i miejscem zakupu)</li>
                        <li>bez parametrów szczegółowych np. drzwi/oświetlenia itp</li>
                    </ul>
                </div>
            )}

            {materialsList === 'premium' && (
                <div className={'section'}>
                    <ul className={'description'}>
                        <li>lista produktów gotowych (wraz z ilością i miejscem zakupu)</li>
                        <li>z parametrami szczegółowymi np. drzwi/oświetlenia itp (wraz z ilością i miejscem zakupu)</li>
                        <li>* zestawienie materiałow nie zawiera liczby płyt GK, klejów, osprzętu elektrycznego i taśm</li>
                    </ul>
                </div>
            )}
                </>
                )}

            <h2>PROJEKT WYKONAWCZY:</h2>
            <hr className={'line'}/>

            <div className={'section'}>
            <label>
                <input type="radio" name="projectType" value="standard" checked={projectType === 'standard'} onChange={() => setProjectType('standard')} /> WERSJA STANDARD
            </label>
            <label>
                <input type="radio" name="projectType" value="premium" checked={projectType === 'premium'} onChange={() => setProjectType('premium')} /> WERSJA PREMIUM
            </label>
            </div>

            <MaterialsDescriptionTable option={projectType}/>

            <h3>NADZÓR</h3>
            <hr className={'line'}/>

            <div className="section">
                <label className="label">
                    <input
                        type="checkbox"
                        className="input"
                        checked={supervisionChecked}
                        onChange={(e) => setSupervisionChecked(e.target.checked)}
                    />
                    Nadzór inwestorski pod klucz (w promieniu 50km od Mińska Mazowieckiego )
                </label>
                {supervisionChecked && (
                    <div className={'description'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in enim non arcu laoreet pellentesque. Donec interdum nulla eu placerat fermentum. Etiam eu neque vehicula, laoreet augue nec, tempor ipsum. Proin porta euismod dignissim. Vivamus ornare, sapien sed egestas tincidunt, velit enim tempus mauris, a imperdiet erat tellus vitae arcu. Mauris fringilla consequat neque sit </div>
                    )}
            </div>

            <h3>OPCJE VIP</h3>
            <hr className={'line'}/>
            <VipOption/>

            <p>* koszty próbek ponosi inwestor</p>
                <div>

                <div className={'prise'}>Cena projektu: <span>0 </span>zł </div>

                </div>
            <div className="inputGroup">
                <label>Email:</label>
                <input type="email" name="email" />
            </div>
            <br/>
            <button type="submit" className="submitButton">Wyślij</button>

            <p>W celu weryfikacji oferty lub chęci podjęcia współpracy - skontaktuj się z nami.</p>

            <div>
                <p>Emilia Bogdanowicz Architekt</p>
                <p>ul. Warszawska 35 lok. 3</p>
                <p>05-300 Mińsk Mazowiecki</p>
                <p>tel. +48 606 168 377</p>
            </div>

            <div className="inputGroup">
                <label>Imię:</label>
                <input type="text" name="name" />
            </div>
            <div className="inputGroup">
                <label>Telefon:</label>
                <input type="text" name="phone" />
            </div>

            <div className="inputGroup">
                <label>Wiadomość:</label>
                <textarea name="message"></textarea>
            </div>

        </form>

        </>
    );
}

export default ComplexForm;
