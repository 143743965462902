import React, { useState } from "react";

function VipOption() {
    const [checkboxStates, setCheckboxStates] = useState({
        individualValuation: false,
        materialSelection: false,
        technicalProject: false,
        demolitionProject: false,
        levelDifferencesProject: false,
        underfloorHeatingProject: false,
        officialProcedures: false,
        ventilationProject: false,
        acUnitsProject: false,
        mechanicalVentilationProject: false,
        metalElementsProject: false,
        stoneElementsProject: false,
        windowDecorationsSelection: false,
        detailsProject: false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates(prevStates => ({
            ...prevStates,
            [name]: checked
        }));
    };

    return (
        <table>
            <thead>
            <tr>
                <th>Wybór</th>
                <th>Usługa</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><input type="checkbox" name="individualValuation" checked={checkboxStates.individualValuation} onChange={handleCheckboxChange} /></td>
                <td>Wyceny indywidualne (mebli na zamówienie, schodów, blatów, drzwi)</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="materialSelection" checked={checkboxStates.materialSelection} onChange={handleCheckboxChange} /></td>
                <td>Dobór materiałów VIP - prezentacja próbek materiałów</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="technicalProject" checked={checkboxStates.technicalProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt techniczny ogólnobudowlany: wytyczne ogólnobudowlane</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="demolitionProject" checked={checkboxStates.demolitionProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt wyburzeń i zamurowań</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="levelDifferencesProject" checked={checkboxStates.levelDifferencesProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt techniczny wykonania uskoków i otworów</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="underfloorHeatingProject" checked={checkboxStates.underfloorHeatingProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt lokalizacji ogrzewania podłogowego</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="officialProcedures" checked={checkboxStates.officialProcedures} onChange={handleCheckboxChange} /></td>
                <td>Załatwianie formalności urzędowych</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="ventilationProject" checked={checkboxStates.ventilationProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt lokalizacji nawiewów i wywiewów wentylacji</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="acUnitsProject" checked={checkboxStates.acUnitsProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt lokalizacji jednostek wewnętrznych klimatyzacji</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="mechanicalVentilationProject" checked={checkboxStates.mechanicalVentilationProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt wentylacji mechanicznej</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="metalElementsProject" checked={checkboxStates.metalElementsProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt elementów metalowych</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="stoneElementsProject" checked={checkboxStates.stoneElementsProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt elementów kamiennych</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="windowDecorationsSelection" checked={checkboxStates.windowDecorationsSelection} onChange={handleCheckboxChange} /></td>
                <td>Dobór dekoracji okiennych i tekstyliów</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="detailsProject" checked={checkboxStates.detailsProject} onChange={handleCheckboxChange} /></td>
                <td>Projekt detali</td>
            </tr>
            </tbody>
        </table>
    );
}

export default VipOption;
