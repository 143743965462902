import React, { useState } from 'react';

function QuoteForm() {
    // Stan dla każdego z wyborów oraz dla sumy
    const [size, setSize] = useState('');
    const [rooms, setRooms] = useState('');
    const [inventory, setInventory] = useState('');
    const [total, setTotal] = useState(0);

    // Cennik
    const prices = {
        size: {
            small: 1000,
            medium: 2000,
            large: 3000,
        },
        rooms: {
            '1': 500,
            '2': 1000,
            '3': 1500,
            '4': 2000,
            '5': 2500,
        },
        inventory: {
            yes: 300,
            no: 0,
        },
    };

    // Obliczanie sumy
    const calculateTotal = () => {
        const sizePrice = prices.size[size] || 0;
        const roomsPrice = prices.rooms[rooms] || 0;
        const inventoryPrice = prices.inventory[inventory] || 0;
        setTotal(sizePrice + roomsPrice + inventoryPrice);
    };

    // Aktualizacja stanu i ponowne obliczenie sumy przy każdej zmianie
    const handleSizeChange = (e) => {
        setSize(e.target.value);
        calculateTotal();
    };

    const handleRoomsChange = (e) => {
        setRooms(e.target.value);
        calculateTotal();
    };

    const handleInventoryChange = (e) => {
        setInventory(e.target.value);
        calculateTotal();
    };

    return (
        <form>
            <div>
                <h2>Wielkość domu:</h2>
                <select value={size} onChange={handleSizeChange}>
                    <option value="">Wybierz...</option>
                    <option value="small">Mały</option>
                    <option value="medium">Średni</option>
                    <option value="large">Duży</option>
                </select>
            </div>

            <div>
                <h2>Ilość pomieszczeń:</h2>
                <select value={rooms} onChange={handleRoomsChange}>
                    <option value="">Wybierz...</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
            </div>

            <div>
                <h2>Inwentaryzacja:</h2>
                <input type="radio" name="inventory" value="yes" checked={inventory === 'yes'} onChange={handleInventoryChange} /> Tak
                <input type="radio" name="inventory" value="no" checked={inventory === 'no'} onChange={handleInventoryChange} /> Nie
            </div>

            <div>
                <h2>Suma całkowita:</h2>
                <p>{total} zł</p>
            </div>
        </form>
    );
}

export default QuoteForm;

